import { TemplateResult, unsafeCSS } from 'lit';
import { repeat } from 'lit-html/directives/repeat.js';
import { property, query, state } from 'lit/decorators.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENChip } from '../chip/chip';
import { ENMenuItem } from '../menu-item/menu-item';
import { ENMenu } from '../menu/menu';
import styles from './chip-group.scss';

/**
 * Component: en-chip-group
 * - Chip group is used to display chips in a row with additional expand functionality.
 * @slot - The area to slot in ENChips
 */
export class ENChipGroup extends ENElement {
  static el = 'en-chip-group';

  private elementMap = register({
    elements: [
      [ENChip.el, ENChip],
      [ENMenu.el, ENMenu],
      [ENMenuItem.el, ENMenuItem]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private chipEl = unsafeStatic(this.elementMap.get(ENChip.el));
  private menuEl = unsafeStatic(this.elementMap.get(ENMenu.el));
  private menuItemEl = unsafeStatic(this.elementMap.get(ENMenuItem.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * If true will keep old tooltip, else replace with new tooltip
   * Default is true
   */
  @property({ type: Boolean })
  keepOldTooltip?: boolean = true;

  /**
   * Set the number of visible chips. Don't set autoDetermineChipsVisible along with this property
   */
  @property({ type: Number })
  chipsVisible?: number;

  /**
   * If set to true, align chips to right. Default is false.
   */
  @property({ type: Boolean })
  alignChipsToRight?: boolean = false;

  /**
   * Automatically determine no of chips to show and no of chips to hide. Don't set chipsVisible along with this property. Default value is false.
   */
  @property({ type: Boolean })
  autoDetermineChipsVisible?: boolean = false;

  /**
   * Set child chip max width except count chip. Use only one, either it or `setFlexOnChips`. It will override by `setFlexOnChips`. This property or setFlexOnChips is required to set in order to control text overflow.
   */
  @property({ type: String })
  chipsMaxWidth?: string;

  /**
   * Set child chip min width except count chip. This property is required to set in order to automatically determine chips visible.
   */
  @property({ type: String })
  chipsMinWidth?: string = '0px';

  /**
   * Set flex:1 on every child chip except count chip. Use only one, either it or `chipsMaxWidth`. It will override `chipsMaxWidth`. This property or chipsMaxWidth is required to set in order to control text overflow.
   */
  @property({ type: Boolean })
  setFlexOnChips?: boolean;

  /**
   * Enable handling text overflow on chips. Default is false. Relevance of enabling it is only if either flex is set on chip or max width is applied on chip.
   */
  @property({ type: Boolean })
  textOverflow?: boolean = false;

  /**
   * If set to true disable all the chips inside it. Default is set to false.
   */
  @property({ type: Boolean })
  isDisabled?: boolean = false;

  /**
   * It true show tooltip on chips if they overflow. Default is true. Relevance of this property is only if textOverflow is set.
   */
  @property({ type: Boolean })
  showTooltipOnOverflowChips?: boolean = true;

  /**
   * It true show counter chip dropdown menu. Default is false.
   */
  @property({ type: Boolean })
  showCounterChipDropdownMenu?: boolean = false;

  /**
   * Set it in order to change chip dropdown menu max height. Expected valid height string css value.
   */
  @property()
  chipDropdownMenuMaxHeight?: string = '205px';

  /**
   * It true dismiss counter and expand remaining chips. Default is true.
   */
  @property({ type: Boolean })
  dismissCounterAndExpandChips?: boolean = true;

  /**
   * Enable it only when have to show all chips in single line and overflow is handled. Default is false.
   */
  @property({ type: Boolean })
  disableChipWrapping?: boolean = false;

  /**
   * Enable it only when to hide chip group. Default is false.
   */
  @property({ type: Boolean })
  hideChipGroup?: boolean = false;

  /**
   * Set tooltip on chip css position to fixed.
   */
  @property({ type: Boolean })
  setTooltipCSSPositionFixed?: boolean = false;

  /**
   * Query all the chips
   */
  // @queryAssignedElements({ flatten: true })
  // chips: Array<ENChip>;

  /**
   * Query the chip group counter
   */
  @query('.en-c-chip-group__counter')
  chipGroupCounter: ENChip;

  /**
   * Positions the dropdown menu panel absolutely to the counter chip.
   * - **default** places the menu panel to the bottom right of the trigger
   * - **bottom-right** places the menu panel to the bottom right of the trigger
   * - **bottom-left** places the menu panel to the bottom left of the trigger
   * - **top-right** places the menu panel to the top right of the trigger
   * - **top-left** places the menu panel to the top left of the trigger
   * - **left** places the menu panel to the left of the trigger
   * - **right** places the menu panel to the right of the trigger
   * Default is bottom-right.
   */
  @property()
  menuPosition?: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left' | 'left' | 'right' = 'bottom-right';

  /**
   * Expects string value of trigger menu panel width.
   * Default value is max-content
   */
  @property()
  triggerMenuPanelWidth?: string = 'max-content';

  /**
   * If true add some delay in text overflow handling. This delay is required to handle specific cases on different browsers. Default is false. Recommended not to set explicitly
   */
  @property({ type: Boolean })
  addSomeDelayInTextOverflowHandling?: boolean = false;

  /**
   * If true, show tooltip on menu chip. Default is true.
   */
  @property({ type: Boolean })
  showTooltipOnMenuChip?: boolean = true;

  @state()
  chipsEls: Array<ENChip>;

  get chips(): Array<ENChip> {
    if (!this.chipsEls) {
      this.chipsEls = [...this.querySelectorAll<ENChip>(this.elementMap.get(ENChip.el))];
    }
    return this.chipsEls;
  }

  set chips(chips: Array<ENChip>) {
    this.chipsEls = chips;
  }

  private observer: IntersectionObserver;

  /**
   * First updated lifecycle
   * 1. Hide the chip counter on load
   * 2. Set the visible chips based on the visible property
   */
  firstUpdated() {
    this.chipGroupCounter.classList.add('en-u-is-vishidden'); /* 1 */

    this.setChipsVisible(); /* 2 */
    setTimeout(() => {
      const slot = this.shadowRoot.querySelector('slot');
      slot.addEventListener('slotchange', async (evt) => {
        const slot = evt.target as HTMLSlotElement;
        if (slot) {
          const chips = slot.assignedElements();
          if (chips) {
            this.chips = chips as ENChip[];
          }
        }
        if (this.chipsVisible > 0) {
          this.hideChipGroup = true;
          this.chipsVisible = 0;
          await this.updateComplete;
        }
        this.updateChipsInChipGroup();
      });
      this.updateChipsInChipGroup();
    }, 0);

    this.setupObserver(); /* 3*/
  }

  private setupObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // The chip group has become visible
            this.updateChipsInChipGroup(); // Force update the chips
          }
        });
      },
      {
        root: null, // Use the viewport as the container
        threshold: 0.1 // Trigger when 10% of the element is visible
      }
    );

    const chipGroupEl = this.shadowRoot.querySelector('.en-c-chip-group');
    if (chipGroupEl) {
      this.observer.observe(chipGroupEl);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.observer) {
      this.observer.disconnect(); // Clean up the observer
    }
  }

  /**
   * Updated lifecycle
   * 1. Handle flex property update
   * 2. Handle maxWidth property update
   * 3. Handle disableChipWrapping property
   * 4. Handle textOverflow property
   * 5. Handle showTooltipOnOverflowChips property
   * 6. Handle chipsVisible property
   * 7. Handle chipsMinWidth property
   * 8. Handle autoDetermineChipsVisible property
   * 9. Handle addSomeDelayInTextOverflowHandling property
   * @param changedProperties - A map of changed properties in the component after an update.
   */
  updated(changedProperties: Map<string, unknown>) {
    let callUpdateChipsInChipGroup = false;
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'setFlexOnChips' && this.setFlexOnChips !== oldValue) {
        /* 1 */
        callUpdateChipsInChipGroup = true;
      } else if (propName === 'chipsMaxWidth' && this.chipsMaxWidth !== oldValue) {
        /* 2 */
        callUpdateChipsInChipGroup = true;
      } else if (propName === 'disableChipWrapping' && this.disableChipWrapping !== oldValue) {
        /* 3 */
        callUpdateChipsInChipGroup = true;
      } else if (propName === 'textOverflow' && this.textOverflow !== oldValue) {
        /* 4 */
        callUpdateChipsInChipGroup = true;
      } else if (propName === 'showTooltipOnOverflowChips' && this.showTooltipOnOverflowChips !== oldValue) {
        /* 5 */
        callUpdateChipsInChipGroup = true;
      } else if (propName === 'chipsVisible' && this.chipsVisible !== oldValue) {
        /* 6 */
        this.setChipsVisible();
      } else if (propName === 'chipsMinWidth' && this.chipsMinWidth !== oldValue) {
        /* 7 */
        callUpdateChipsInChipGroup = true;
      } else if (propName === 'autoDetermineChipsVisible' && this.autoDetermineChipsVisible !== oldValue && this.autoDetermineChipsVisible) {
        /* 8 */
        callUpdateChipsInChipGroup = true;
      } else if (propName === 'addSomeDelayInTextOverflowHandling' && this.addSomeDelayInTextOverflowHandling !== oldValue) {
        /* 9 */
        callUpdateChipsInChipGroup = true;
      }
    });
    if (callUpdateChipsInChipGroup) {
      this.updateChipsInChipGroup();
    }
  }

  /**
   * @param el Element
   * @returns string consist of element font-weight, font-size and font-family
   */
  private getCanvasFont(el: Element) {
    if (el) {
      const { fontWeight, fontSize, fontFamily } = getComputedStyle(el);
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    }
    return ``;
  }

  /**
   *
   * @param text String whose canvas length has to be measured
   * @param font Font weight, size and family of element which renders that string so that exact length can be measured
   * @returns Canvas render length of string
   */
  private getTextWidth(text: string, font: string) {
    // re-use canvas object for better performance
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  /**
   * Set visible chips
   * 1. If a value for visible is greater than 1 but less than the total number of chips, then loop through all the chips.
   * 2. If the chip index is greater or equal to the visible value, then hide those chips.
   * 3. Set the text inside the counter chip to how many chips are hidden.
   * 4. If chipsVisible is increased then show hidden chips.
   */
  setChipsVisible() {
    /* 1 */
    if (this.chipsVisible > 0 && this.chipsVisible < this.chips.length) {
      this.chips.forEach((chip, index) => {
        /* 2 */
        if (index >= this.chipsVisible) {
          chip.isDismissed = true;
        } else {
          chip.handleChipTextOverflow();
        }
      });
      /* 3 */
      const numChipsHidden = this.chips.length - this.chipsVisible;
      this.chipGroupCounter.isDismissed = false;
      this.chipGroupCounter.textContent = '+' + numChipsHidden;
    } else {
      this.chips.forEach((chip) => {
        /* 4 */
        chip.isDismissed = false;
      });
      this.chipGroupCounter.isDismissed = true;
    }
  }

  updateChipsInChipGroup() {
    this.setChipsPropeties();

    setTimeout(() => {
      if (this.autoDetermineChipsVisible && this.disableChipWrapping) {
        this.setChipsVisibleAccordingToAvailableChipWidth();
      }
      this.hideChipGroup = false;
    }, 10);
  }

  /**
   * Set chip and chip group style properties
   */
  setChipsPropeties() {
    if (this.disableChipWrapping) {
      this.style.setProperty('--en-chip-group-dev-flex-wrap', 'nowrap');
    } else {
      this.style.setProperty('--en-chip-group-dev-flex-wrap', 'wrap');
    }
    for (const chip of this.chips) {
      if (this.setFlexOnChips) {
        chip.flex = 1;
      } else if (this.chipsMaxWidth) {
        chip.maxWidth = this.chipsMaxWidth;
      }
      if (this.chipsMinWidth) {
        chip.minWidth = this.chipsMinWidth;
      }
      chip.textOverflow = this.textOverflow;
      chip.showTooltipOnOverflow = this.showTooltipOnOverflowChips;
      chip.addSomeDelayInTextOverflowHandling = this.addSomeDelayInTextOverflowHandling;
    }
  }

  /**
   * Get counter chip maximum possible width
   * @returns Counter chip maximum possible width
   */
  private getCounterChipMaxPossibleWidth() {
    const chips = this.chips;
    const chipGroupCounter = this.chipGroupCounter;
    const chipsCount = chips.length;
    if (chipsCount > 0) {
      const chipButton = chipGroupCounter.shadowRoot.querySelector('button');
      const { paddingLeft, paddingRight, borderLeftWidth, borderRightWidth } = getComputedStyle(chipButton);
      const parsedPaddingLeft = parseFloat(paddingLeft);
      const parsedPaddingRight = parseFloat(paddingRight);
      const parsedBorderLeftWidth = parseFloat(borderLeftWidth);
      const parsedBorderRightWidth = parseFloat(borderRightWidth);
      const fontProperties = this.getCanvasFont(chipButton);
      const counterChipTextRequiredWidth = Math.ceil(this.getTextWidth(`+${chipsCount}`, fontProperties));
      const expectedCounterChipWidth =
        parsedPaddingLeft + parsedPaddingRight + parsedBorderLeftWidth + parsedBorderRightWidth + counterChipTextRequiredWidth;
      return expectedCounterChipWidth;
    }
    return 0;
  }

  /**
   * Set `chipVisible` property according to available width
   */
  private setChipsVisibleAccordingToAvailableChipWidth() {
    const chipGroupEl = this.shadowRoot.querySelector('.en-c-chip-group');
    const chips = this.chips;
    if (chipGroupEl) {
      const chipGroupWidth = chipGroupEl.clientWidth;
      let chipsWidth = 0;
      const chipsEl = [];
      for (const chip of chips) {
        const chipEl = chip.shadowRoot?.querySelector('button');
        if (chipEl) {
          chipsEl.push(chipEl);
          chipsWidth += chipEl.offsetWidth;
        }
      }
      const { paddingLeft, paddingRight, gap } = getComputedStyle(chipGroupEl);
      const parsedPaddingLeft = parseFloat(paddingLeft);
      const parsedPaddingRight = parseFloat(paddingRight);
      const parsedGap = parseFloat(gap);
      let spaceRequiredbyChipGroupContent = chipsWidth + (chips.length - 1) * parsedGap + parsedPaddingLeft + parsedPaddingRight;
      if (spaceRequiredbyChipGroupContent > chipGroupWidth) {
        const counterWidth = this.getCounterChipMaxPossibleWidth();
        let extraSpace = spaceRequiredbyChipGroupContent - (chipGroupWidth - counterWidth);
        let minimizeChipsCount = 0;
        for (let ind = chipsEl.length - 1; ind >= 0; --ind) {
          const chipWidthWithGap = chipsEl[ind].offsetWidth + parsedGap;
          if (extraSpace < chipWidthWithGap) {
            if (chips.length > minimizeChipsCount + 1) {
              // Show at least one chip
              ++minimizeChipsCount;
            }
            break;
          }
          extraSpace -= chipWidthWithGap;
          ++minimizeChipsCount;
        }
        this.chipsVisible = chips.length - minimizeChipsCount;
      }
    }
  }

  /**
   * Handle on click events
   * 1. Hide the counter chip when clicked
   * 2. Set all the chips to be active except any that have been dismissed, when the counter chip is clicked
   * 3. Dispatch a custom event
   */
  handleOnClick(e: MouseEvent) {
    e.preventDefault();
    if (!this.showCounterChipDropdownMenu && this.dismissCounterAndExpandChips) {
      /* 1 */
      const target = e.target as ENChip;
      target.isDismissed = true;
      /* 2 */
      this.chips.forEach((chip) => {
        if (!chip.classList.contains('en-is-dismissed')) {
          chip.isDismissed = false;
        }
      });
    }

    /* 3 */
    this.dispatch({ eventName: 'showChips' });
  }

  handleChipClose(evt: CustomEvent) {
    const chip = evt.detail.chip;
    const chipContent = evt.detail.chipContent;
    const dataInfo = evt.detail.dataInfo;
    const isTextOverflowCase = evt.detail.isTextOverflowCase;
    this.dispatch({ eventName: 'close', detailObj: { chip: chip, chipContent, dataInfo, isTextOverflowCase: isTextOverflowCase || false } }); /* 2 */
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-chip-group', {
      'hide-chip-group': this.hideChipGroup,
      'align-chips-right': this.alignChipsToRight
    });
    return html`
      <div class="${componentClassNames}">
        <slot></slot>
        ${this.showCounterChipDropdownMenu && this.chipsVisible > 0
          ? html`<${this.menuEl} .isActive=${false} position="${this.menuPosition}" style="--en-menu-panel-max-height:${this.chipDropdownMenuMaxHeight};--en-menu-panel-width:${this.triggerMenuPanelWidth};">
            <${this.chipEl} slot="trigger" .keepOldTooltip=${this.keepOldTooltip} class="en-c-chip-group__counter" variant="secondary" ?isDismissed=${true} ?isDisabled=${this.isDisabled} @click=${
              this.handleOnClick
            }>+</${this.chipEl}>
            ${repeat(
              this.chips.slice(this.chipsVisible),
              (chip: ENChip, index) => `chip${chip.originalText}${index}`,
              (chip: ENChip, index) => {
                return html`
            <${this.menuItemEl} data-testid="${`chip${index}`}" variant="tertiary">
              <${this.chipEl}
                .keepOldTooltip=${this.keepOldTooltip} 
                .setTooltipCSSPositionFixed=${!!chip.chipText && !!chip.tooltipText} 
                chipText=${chip.chipText || ''} 
                tooltipText=${this.showTooltipOnMenuChip ? chip.tooltipText || '' : ''}
                .showTooltipOnMenuChip=${this.showTooltipOnMenuChip} 
                @close=${this.handleChipClose} 
                dataInfo=${chip.dataInfo || ''} 
                .isDismissible=${chip.isDismissible || false} 
                ?isDisabled=${this.isDisabled} 
                variant="${chip.variant}">
                  ${this.showTooltipOnMenuChip ? `${chip?.textContent}`.replace(/\s+/g, ' ').trim() : chip.chipText}
              </${this.chipEl}>
            </${this.menuItemEl}>`;
              }
            )}

          </${this.menuEl}>`
          : html`<${this.chipEl} .keepOldTooltip=${this.keepOldTooltip} ?isDisabled=${this.isDisabled} class="en-c-chip-group__counter" variant="secondary" ?isDismissed=${true} @click=${this.handleOnClick}>+</${this.chipEl}>`}
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENChipGroup.el) === undefined) {
  customElements.define(ENChipGroup.el, ENChipGroup);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-chip-group': ENChipGroup;
  }
}
